import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import ContactForm from "@components/forms/contact-form";
import { StyledSection, LeftBox, StyledHeading, StyledDesc } from "./style";

const ContactArea = () => {
  return (
    <StyledSection>
      <Container>
        <Row alignItems="center">
          <Col lg={6}>
            <LeftBox>
              <StyledHeading>
                No hay obligación de reservar en este momento
              </StyledHeading>
              <StyledDesc>
                así que no dude en escribirnos, incluso si es solo una pregunta
                simple, ¡el personal servicial de Eventos Ghupa estará encantado
                de ayudarlo!
              </StyledDesc>
            </LeftBox>
          </Col>
          <Col lg={6}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </StyledSection>
  );
};

export default ContactArea;
