import React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@components/page-header/layout-02";
import ContactArea from "@containers/contacto/contacto-area";
import Cta from "@containers/cta/layout-01";

const ContactoPage = ({ pageContext, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Contacto" />
      <Header />
      <main className="site-wrapper-reveal">
        <PageHeader
          pageContext={pageContext}
          location={location}
          title="Contacto"
        />
        <ContactArea />
        <Cta />
      </main>
      <Footer />
    </Layout>
  );
};

export default ContactoPage;
