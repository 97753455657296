import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { Container, Row, Col } from "@ui/wrapper";
import { HeadingType, VideoButtonType } from "@utils/types";
import { SectionWrap, StyledTitle } from "./style";

const CtaArea = () => {
  const ctaData = useStaticQuery(graphql`
    query CtaOneQuery {
      file(relativePath: { eq: "bg/home-infotechno-video-intro-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            formats: WEBP
            quality: 100
          )
        }
      }
    }
  `);
  const bgImage = convertToBgImage(getImage(ctaData.file));

  return (
    <SectionWrap {...bgImage}>
      <Container>
        <Row>
          <Col lg={6} md={10} xs={11} ml="auto" mr="auto">
            <StyledTitle>
              LO MÁS IMPORTANTE ES QUE TRABAJAMOS CON USTED PARA SATISFACER LAS
              NECESIDADES DE SU EVENTO.
            </StyledTitle>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

CtaArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    video_button: PropTypes.shape(VideoButtonType),
  }),
};

export default CtaArea;
